.skipLink {
	align-items: center;
	background-color: #50a8ff;
	color: #fff;
	display: flex;
	height: 30px;
	left: 0;
	padding: 8px;
	position: absolute;
	transform: translateY(-100%);
	transition: transform 0.1s;
	z-index: 2;

	&:focus {
		transform: translateY(0%);
	}
}
