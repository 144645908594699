@import 'src/styles/mixins';

.badge {
	@include flex($v_h_align: center, $c_gap: 8px, $wrap: wrap);

	min-width: 70px;
	text-align: center;
	word-break: break-word;
	@include block_480 {
		text-align: left;
		width: 100%;
	}
}

/* Color: */
.primary {
	background-color: var(--primary-outlined-hover-color);
	color: var(--primary-main-color);
}

.warn,
.await {
	background-color: var(--warning-outlined-hover-bg);
	color: var(--warning-dark-color);
}

.success {
	background-color: var(--success-outlined-hover-bg);
	color: var(--success-dark-color);
}

.danger {
	background-color: var(--err-outlined-hover-bg);
	color: var(--err-main-color);
}

.secondary {
	background-color: var(--hover-action-color);
	color: var(--secondary-text-color);
}

.expired-warn {
	background-color: var(--warning-outlined-hover-bg);
	color: var(--warning-main-color);
}

.expired-danger {
	background-color: rgb(69 69 79 / 8%);
	color: var(--nav-active-color);
}

.rounded-sm-extra {
	border-radius: 4px;
}

.rounded-sm {
	border-radius: 8px;
}

.rounded-md {
	border-radius: 16px;
}

.size-sm {
	padding: 3px 10px;
}

.size-md {
	padding: 7px 12px;
}

.fullWidth {
	width: 100%;
}
