@import 'src/styles/mixins';

.chartLegends {
  @include flex($direction: column, $gap: 12px);
  border-radius: 16px;

  @include block_480 {
    gap: 8px
  }
}

.chartLegend {
  @include flex($direction: column, $gap: 8px);

  background-color: var(--primary-contrast-color);
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.08), 0px 12px 16px -4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 10px 16px;
  transition: background-color 0.3s ease-out;

  @include block_480 {
    border-radius: 8px;
  }

  & div {
    @include flex($h_align: space-between, $gap: 8px, $v_align: center);
  }

  h6, p, small {
    transition: color 0.3s ease-out;
  }

  &:hover, &.active {
    background-color: var(--primary-main-color);

    h6, p, small {
      color: var(--primary-contrast-color);
    }
  }

  h6 {
    color: var(--primary-text-color);
  }

  p {
    text-transform: uppercase;
    color: var(--secondary-text-color);
  }

  small {
    color: var(--primary-text-color);
  }
}

.doughnutWrapper {
  position: relative;
}

.doughnutTooltips {
  position: absolute;
}

.doughnut {
  width: 603px;
  height: 603px;

  @include block_1530 {
    width: 507px;
    height: 507px;
  }

  @include block_992 {
    width: 452px;
    height: 452px;
  }

  @include block_768 {
    width: 341px;
    height: 341px;
  }

  @include block_576 {
    width: 300px;
    height: 300px;
  }
}