@import 'src/styles/mixins';

.footerLayer {
  padding: 40px 40px 0;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: var(--primary-contrast-color);

  @include block_1440 {
    padding: 35px 30px 0;
  }

  @include block_992 {
    padding: 20px 20px 0;
  }

  @include block_768 {
    padding: 12px 0 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  @include block_480 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.footerLayer1 {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 -100px 40px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  margin-top: 150px;

  @include block_1600 {
    box-shadow: 0 -80px 32px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  }

  @include block_1440 {
    box-shadow: 0 -60px 24px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  }

  @include block_1200 {
    box-shadow: 0 -40px 16px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  }

  @include block_992 {
    margin-top: 100px;
    box-shadow: 0 -30px 12px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  }

  @include block_768 {
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  }

  @include block_480 {
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.01);
  }
}

.footerLayer2 {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 -100px 40px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);

  @include block_1600 {
    box-shadow: 0 -80px 32px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);
  }

  @include block_1440 {
    box-shadow: 0 -60px 24px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);
  }

  @include block_1200 {
    box-shadow: 0 -40px 16px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);
  }

  @include block_992 {
    box-shadow: 0 -30px 12px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);
  }

  @include block_768 {
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);
  }

  @include block_480 {
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.02);
  }
}

.footerLayer3 {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 -100px 40px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);

  @include block_1600 {
    box-shadow: 0 -80px 32px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);
  }

  @include block_1440 {
    box-shadow: 0 -60px 24px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);
  }

  @include block_1200 {
    box-shadow: 0 -40px 16px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);
  }

  @include block_992 {
    box-shadow: 0 -30px 12px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);
  }

  @include block_768 {
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);
  }

  @include block_480 {
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.03);
  }
}

.footerLayer4 {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 -100px 40px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);

  @include block_1600 {
    box-shadow: 0 -80px 32px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);
  }

  @include block_1440 {
    box-shadow: 0 -60px 24px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);
  }

  @include block_1200 {
    box-shadow: 0 -40px 16px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);
  }

  @include block_992 {
    box-shadow: 0 -30px 12px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);
  }

  @include block_768 {
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);
  }

  @include block_480 {
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.04);
  }
}

.footerLayer5 {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 -100px 40px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);

  @include block_1600 {
    box-shadow: 0 -80px 32px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);
  }

  @include block_1440 {
    box-shadow: 0 -60px 24px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);
  }

  @include block_1200 {
    box-shadow: 0 -40px 16px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);
  }

  @include block_992 {
    box-shadow: 0 -30px 12px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);
  }

  @include block_768 {
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);
  }

  @include block_480 {
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.05);
  }
}

.footer {
  @include flex($direction: column);

  width: 100%;
  padding: 40px 0 42px 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 -100px 40px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);

  @include block_1600 {
    box-shadow: 0 -80px 32px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);
  }

  @include block_1440 {
    padding: 32px 32px 0;
    box-shadow: 0 -60px 24px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);
  }

  @include block_1200 {
    box-shadow: 0 -40px 16px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);
    padding: 28px 28px 0;
  }

  @include block_992 {
    padding-top: 8px;
    padding-bottom: 24px;
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);
  }

  //added now
  @include block_768 {
    padding: 40px 0 24px 0;
    box-shadow: 0 -24px 8px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);
  }

  @include block_576 {
    padding: 24px 0 24px 0;
  }

  @include block_480 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.01), 0 -6px 14px rgba(0, 0, 0, 0.06);
  }

  a:hover {
    color: var(--primary-main-color);

    svg {
      color: var(--primary-main-color);
    }
  }
}

.logo {
  @include flex($v_align: center, $gap: 14px);
  align-self: center;
  margin-bottom: 40px;

  @include block_1440 {
    margin-bottom: 30px;
  }

  @include block_480 {
    margin-bottom: 24px;
  }
}

.footerLayout {
  @include flex($gap: 16px);

  position: relative;
  padding: 32px;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--nav-divider-color);
    top: 0;
    left: 0;
  }

  @include block_1200 {
    flex-direction: column;
  }

  @include block_768 {
    padding: 0;
    gap: 0;
  }
}

.footerLayoutItem {
  flex: 1 1 0;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include block_1920 {
    align-items: unset;
    text-align: left;
  }

  @include block_768 {
    padding: 24px 16px;

    &:not(:first-child):before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--nav-divider-color);
    }
  }

  h6 {
    margin-bottom: 16px;
    color: var(--primary-text-color);

    @include block_768 {
      margin-bottom: 8px;
    }
  }

  .list {
    color: var(--secondary-text-color);
  }
}

.footerExplore, .footerLearn {
  .list {
    @include flex($direction: column, $gap: 8px);
  }

  @include block_768 {
    gap: 4px;
  }
}

.footerCommunicate {
  p {
    color: var(--secondary-text-color);
    margin-bottom: 8px;

    a {
      color: var(--primary-text-color);
    }
  }

  @include block_1440 {
    flex-grow: 2;
  }
}

.engageList {
  @include flex($gap: 16px);
  margin-bottom: 10px;

  a {
    display: flex;
  }

  svg {
    color: var(--nav-default-color);
  }
}

.footerForm {
  @include flex($gap: 10px);

  input {
    width: 100%;
    max-width: 320px;
  }
}

.footerCopyright {
  @include flex($h_align: space-between);
  position: relative;
  padding: 42px 62px 0 60px;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--nav-divider-color);
    top: 0;
    left: 0;
  }

  @include block_768 {
    padding: 24px 16px 0 16px;
  }

  @include block_576 {
    gap: 12px;
    flex-wrap: wrap;
    justify-content: flex-start;

    p {
      flex-basis: 100%;
    }
  }
}