@import 'src/styles/mixins';

.investment {
  padding: 155px 0 185px 0;

  @include block_992 {
    padding: 100px 0;
  }

  @include block_576 {
    padding: 40px 0 32px 0;
  }

  @include block_360 {
    padding: 60px 0 32px 0;
  }
}

.container {
  @include flex($gap: 128px, $h_align: space-between);
  background: linear-gradient(100.26deg, rgba(60, 151, 255, 0.02) 0%, rgba(60, 151, 255, 0.1) 100%);
  border-radius: 24px;
  padding: 32px;

  @include block_1440 {
    gap: 92px
  }

  @include block_992 {
    padding: 32px;
    border-radius: 24px;
  }

  @include block_872 {
    flex-direction: column;
    gap: 32px
  }

  @include block_576 {
    padding: 8px;
    border-radius: 8px;
  }

  & > div {
    flex: 1 1 0;
  }
}

.leftSide {
  @include flex($direction: column, $h_align: center);

  & > h3 {
    margin-bottom: 16px;

    @include block_872 {
      text-align: center;
    }
  }

  & > p {
    margin-bottom: 24px;

    @include block_872 {
      text-align: center;
    }
  }
}

.rightSide {
  @include flex($direction: column);
}
