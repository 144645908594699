@import 'src/styles/mixins';

.detailsWrapper {
  @include flex($direction: column);
}

.details {
  @include flex($direction: column);

  background-color: var(--primary-contrast-color);
  margin-top: 6px;
  padding: 8px;
  border-radius: 10px;

  & > div {
    @include flex($h_align: space-between, $gap: 16px);
    padding: 8px;
    position: relative;
    color: var(--tertiary-text-color);

    &:not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--nav-divider-color);
      width: 100%;
      height: 1px;
    }

    @include block_480 {
      flex-direction: column;
      gap: 8px
    }
  }

  svg {
    vertical-align: bottom;
  }
}

.progressDetail:not(.default) {
  flex-wrap: wrap;
  row-gap: 2px;

  dt, dd {
    flex: 1 1 0;
  }

  dd {
    text-align: right;
  }

  span {
    width: 100%;
  }
}

.claimBtn {
  margin-top: 16px;
  align-self: flex-end;
}

.submitMessage {
  margin-top: 16px;
  flex-wrap: nowrap;
}