@import url('../assets/fonts/creatio_display/stylesheet.css');
@import 'animations';
@import 'normalize';
@import 'focus-visible';
@import 'typography';
@import 'src/styles/mixins';

:root {
  /* Shadows: */
  --shadow: 0 0 0 4px rgb(13 110 253 / 25%);
  --inset-shadow: inset 0 0 0 4px rgb(13 110 253 / 25%);
  --button-hover-shadow: 0 2px 8px -4px rgb(0 0 0 / 8%),
  0 4px 20px -6px rgb(0 0 0 / 8%);

  /* Font Families */
  --font-base: 'Creato Display', -apple-system, blinkmacsystemfont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;

  /* Font Colors */
  --primary-text-color: #19192c;
  --secondary-text-color: #535361;
  --tertiary-text-color: #81818b;
  --disabled-text-color: #afafb5;
  --text-bg-color: #f7f7f7;

  /* Primary Colors: */
  --primary-nav-bg: rgb(255 255 255 / 70%);
  --primary-main-color: #3c97ff; // Main color used by most components
  --primary-light-color: #6eb1ff; // Alternative light shade
  --primary-dark-color: #0057c7; // Alternative dark shade
  --primary-contrast-color: #fff; // Color that keeps a contrast ratio above AA when XX. main is used as a bg. color
  --primary-contained-hover-color: #125bb0; // Fill background color for contained variant components in hover state (Button, FAB, etc)
  --primary-outlined-hover-color: #edf5ff; // Fill background color for outlined & text variant components in hover state (Button,etc)
  --primary-outlined-resting-color: #9dc9fc; // Main color used by most components

  /* Action Colors */
  --active-action-color: #82828d; // Fill color for components in active state (List, Table, etc)
  --hover-action-color: #f6f6f7; // Fill background for components in hover state (List, Table, etc)
  --selected-action-color: #ededee; // Fill background for components in selected state (List, Table, etc)
  --disabled-action-color: #a7a7af; // Content color for components in disabled state (Button, List, Table, etc)
  --disabled-bg-action-color: #e3e3e5; // Fill background for components in disabled state (List, Table, etc)
  --focus-action-color: #e3e3e5; // Fill background for components in focus state (List, Table, etc)

  /* Navigation */
  --nav-default-color: #82828d; // Default state for all navigation buttons/Icons (Sidebar, Header, Sub-Nav, Pagination)
  --nav-active-color: #45454f; // Fulfillment state for all navigation buttons/Icons (Sidebar, Header, Sub-Nav, Pagination)
  --nav-hover-color: #52525e; // Hover state for all navigation buttons/Icons (Sidebar, Header, Sub-Nav, Pagination)
  --nav-disabled-color: #a7a7af; // Disabled state for all navigation buttons/Icons (Sidebae, Header, Sub-Nav, Pagination)
  --nav-disabled-bg-color: #e3e3e5; // Disabled state for all navigation buttons/Icons (Sidebae, Header, Sub-Nav, Pagination)
  --nav-divider-color: #e5e5e9; // Dividing sections

  /* Error */
  --err-main-color: #fd3f3f; // Used for Error alert, fails, unfilled inputs, etc.
  --err-light-color: #ff776b; // Alternative light shade
  --err-dark-color: #c20017; // Alternative dark shade
  --err-contained-hover-bg: #b22c2c; // Fill background color for contained variant components in hover state (Button, FAB, etc)
  --err-outlined-hover-bg: #fff0f0; // Fill background color for outlined & text variant components in hover state (Button,etc)
  --err-outlined-resting-border-color: #fe9f9f; // Used for Error alert, fails, unfilled inputs, etc.
  --err-content-color: #651919; // text color for the error Alert components
  --err-bg: #ffecec; // Background color for the error Alert component

  /* Success */
  --success-main-color: #0fe351; // Used for Success alert, activation, current network, etc.
  --success-light-color: #69ff82; // Alternative light shade
  --success-dark-color: #00ac38; // Alternative dark shade
  --success-contained-hover-bg: #0b9f39; // Fill background color for contained variant components in hover state (Button, FAB, etc)
  --success-outlined-hover-bg: #ecfdf1; // Fill background color for outlined & text variant components in hover state (Button,etc)
  --success-outlined-resting-color: #87f1a8; // Used for success alert, activations, completion inputs, etc..
  --success-content-color: #065b20; // text color for the success Alert components
  --success-bg: #e7fdee; // Background color for the success Alert component

  /* Warning */
  --warning-main-color: #ffa200; // Used for Warning alert, strong suggestions, unfilled optinal inputs, etc.
  --warning-light-color: #ffd349; // Alternative light shade
  --warning-dark-color: #d86700; // Alternative dark shade
  --warning-contained-hover-bg: #b37200; // Fill background color for contained variant components in hover state (Button, FAB, etc)
  --warning-outlined-hover-bg: #fff8eb; // Fill background color for outlined & text variant components in hover state (Button,etc)
  --warning-outlined-resting-color: #ffd180; // Used for warning alert, activations, completion inputs, etc..
  --warning-content-color: #664100; // text color for the warning Alert components
  --warning-bg: #fff6e6; // Background color for the warning Alert component
}

html {
  font-size: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark {
  --primary-nav-bg: #242744;
}

a {
  color: inherit;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;
}

ul {
  list-style-type: none;
}

label {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

img {
  color: transparent; // for hide native alt text
  display: block;
  max-width: 100%;
  position: relative;

  &::after {
    align-items: center;
    background-color: #ccc;
    color: rgb(100 100 100);
    content: attr(alt);
    display: flex;
    font-size: 0.8em;
    height: inherit;
    justify-content: center;
    left: 0;
    line-height: 1em;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: break-spaces;
    width: inherit;
  }
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.main {
  padding-top: 72px;
  padding-left: 32px;
  padding-right: 32px;

  @include block_992 {
    padding-top: 53px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include block_576 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &.container-md {
    max-width: 1007px;
  }
}

.containerTitle {
  @include flex($v_align: center, $h_align: center);
  margin-bottom: 24px;
  border-radius: 24px;
  background-color: var(--primary-contrast-color);
  box-shadow: 0 49px 80px -35px rgba(0, 0, 0, 0.12);
  padding: 16px;
  text-align: center;

  @include block_992 {
    padding: 20px;
  }

  @include block_768 {
    padding: 12px;
    border-radius: 16px;
    margin-bottom: 16px;
    box-shadow: 0 10px 34px -12px rgba(0, 0, 0, 0.12);
  }

  @include block_576 {
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.08), 0px 16px 26px -16px rgba(0, 0, 0, 0.1);
  }

  @include block_480 {
    margin-bottom: 8px;
  }
}

.shadowedBlock {
  box-shadow: 0 49px 80px -35px rgba(0, 0, 0, 0.12);

  @include block_768 {
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.08), 0px 16px 26px -16px rgba(0, 0, 0, 0.1);
  }

  @include block_576 {
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.08), 0px 16px 26px -16px rgba(0, 0, 0, 0.1);
  }
}

.subNavBtns {
  @include flex($v_align: center, $c_gap: 8px);

  background-color: #fff;
  border-radius: 20px;
  padding: 3px;
  width: max-content;
}

button.subNavBtn,
a.subNavBtn {
  border-radius: 14px;
  color: var(--nav-default-color);
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.024rem;
  line-height: 1.42;
  padding: 6px 12px;

  &:hover {
    color: var(--nav-active-color);
  }

  &.active {
    background-color: var(--selected-action-color);
    color: var(--nav-active-color);
  }
}

svg {
  transition: color 0.3s ease-out;

  &[data-for] {
    color: var(--nav-default-color);
    z-index: 0;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.progressBar {
  width: 100%;
  background-color: var(--primary-outlined-hover-color);
  height: 8px;
  padding: 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &:after {
    display: none;
  }
}

.progressBarActive {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-main-color);
  height: 8px;
  transition: width 0.3s ease-out;
}

@media (max-width: 360px) {
  .container {
    max-width: 328px;

    .container-md {
      max-width: 328px;
    }
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 544px;

    &.container-md {
      max-width: 544px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 690px;

    &.container-md {
      max-width: 690px;
    }
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 912px;

    &.container-md {
      max-width: 758px;
    }
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1200px;

    &.container-md {
      max-width: 1200px;
    }
  }
}

.y-scroll-hidden {
  overflow-y: hidden;
  padding-right: var(--scrollbar-width);
}