.modal {
  max-width: 320px;
  width: 100%;
  min-height: 100px;
  height: auto;
  background-color: var(--primary-contrast-color);
  position: relative;
  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn {
  border-radius: 6px;
  padding: 4px;

  &:hover {
    background-color: var(--hover-action-color);
  }
}

.closeModalBtn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.providers {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.providerBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 1px solid var(--focus-action-color);
  padding: 8px 12px;

  svg {
    width: 24px;
    height: 24px;
  }
}