@import 'src/styles/mixins';

.timerWrapper {
  @include flex($direction: column);

  margin-top: 16px;

  button, .buyZenfBtn {
    align-self: flex-start;

    @include block_768 {
      align-self: unset;
      order: 3;
      margin-top: 16px;
    }
  }
}

.message {
  max-width: 256px;
  width: 100%;
  text-align: center;

  @include block_768 {
    margin: 0 auto;
  }
}

.buyZenfBtn {
  background-color: var(--primary-main-color);
  color: var(--primary-contrast-color);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  height: 48px;
  letter-spacing: 0.03rem;
  line-height: 1.5;
  padding: 12px 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    background-color: var(--primary-contained-hover-color);
  }
}