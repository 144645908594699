@import 'src/styles/mixins';

input {
	border: 0;

	&[type='search']::-webkit-search-decoration,
	&[type='search']::-webkit-search-cancel-button,
	&[type='search']::-webkit-search-results-button,
	&[type='search']::-webkit-search-results-decoration {
		display: none;
	}

	&[type='number'] {
		touch-action: none;
	}
}

.input {
	background: linear-gradient(0deg, #fff, #fff) padding-box,
		linear-gradient(180deg, #fafafa 0%, #d6d6d6 100%) border-box;
	border: 1px solid transparent;
	border-radius: 10px;
	color: var(--tertiary-text-color);
	display: inline-block;
	padding: 14px 12px;
	transition: box-shadow 0.3s;

	@include block_768 {
		padding: 10px 8px;
	}

	&:hover {
		box-shadow: 0 4px 14px -4px rgb(0 0 0 / 10%);
		color: var(--secondary-text-color);
	}

	&:focus {
		border: 1px solid var(--primary-main-color);
		box-shadow: none;
		color: var(--primary-text-color);
	}

	&[type='checkbox'] {
		cursor: pointer;
		height: 16px;
		opacity: 0;
		position: relative;
		width: 16px;

		&:focus-visible {
			box-shadow: var(--shadow);
		}

		&:hover:not(:checked) {
			+ .layer {
				background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3 1.6H3.7C2.81634 1.6 2.1 2.31634 2.1 3.2V12.8C2.1 13.6837 2.81634 14.4 3.7 14.4H13.3C14.1837 14.4 14.9 13.6837 14.9 12.8V3.2C14.9 2.31634 14.1837 1.6 13.3 1.6ZM3.7 0C1.93269 0 0.5 1.43269 0.5 3.2V12.8C0.5 14.5673 1.93269 16 3.7 16H13.3C15.0673 16 16.5 14.5673 16.5 12.8V3.2C16.5 1.43269 15.0673 0 13.3 0H3.7Z' fill='%2352525E'/%3E%3C/svg%3E%0A");
			}
		}
	}

	&:checked {
		+ .layer {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.5 0C2.29086 0 0.5 1.79086 0.5 4V12C0.5 14.2091 2.29086 16 4.5 16H12.5C14.7091 16 16.5 14.2091 16.5 12V4C16.5 1.79086 14.7091 0 12.5 0H4.5ZM13.0414 3.85262C13.4475 3.44647 14.1064 3.44763 14.5111 3.85522C14.9138 4.26077 14.9126 4.91563 14.5085 5.31976L6.94473 12.8835L2.49199 8.4308C2.088 8.02681 2.088 7.37181 2.49199 6.96783C2.89558 6.56424 3.54979 6.56378 3.95394 6.96681L6.94473 9.94927L13.0414 3.85262Z' fill='%231A82FB'/%3E%3C/svg%3E%0A");
		}
	}

	&:disabled {
		box-shadow: none;
		color: var(--tertiary-text-color);
		pointer-events: none;
	}
}

.md {
	height: 48px;

	@include block_576 {
		height: 36px;
	}
}

.checkboxWrapper {
	display: flex;
	position: relative;
}

.label {
	@include flex($v_h_align: center);

	background-color: #fff;
	border-radius: 50%;
	height: 32px;
	transition: background-color 0.3s;
	width: 32px;

	&:hover {
		background-color: var(--nav-disabled-color);
	}
}

.layer {
	background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3 1.6H3.7C2.81634 1.6 2.1 2.31634 2.1 3.2V12.8C2.1 13.6837 2.81634 14.4 3.7 14.4H13.3C14.1837 14.4 14.9 13.6837 14.9 12.8V3.2C14.9 2.31634 14.1837 1.6 13.3 1.6ZM3.7 0C1.93269 0 0.5 1.43269 0.5 3.2V12.8C0.5 14.5673 1.93269 16 3.7 16H13.3C15.0673 16 16.5 14.5673 16.5 12.8V3.2C16.5 1.43269 15.0673 0 13.3 0H3.7Z' fill='%2382828D'/%3E%3C/svg%3E%0A");
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	appearance: none;
}
