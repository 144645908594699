@import 'src/styles/mixins';

.items {
  @include flex($direction: column, $gap: 16px);

  @include block_480 {
    gap: 8px
  }
}

.item {
  background-color: var(--primary-contrast-color);
  box-shadow: 0 49px 80px -35px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 24px;

  @include block_1440 {
    padding: 24px;
  }

  @include block_992 {
    border-radius: 24px;
  }

  @include block_768 {
    padding: 16px;
    border-radius: 16px;
  }

  @include block_576 {
    padding: 8px;
    border-radius: 8px;
  }
}

.question {
  @include flex($h_align: space-between);
  width: 100%;
  color: var(--primary-text-color);

  svg {
    width: 42px;
    height: 42px;
    color: var(--nav-default-color);
    transition: transform 0.6s ease-out;

    @include block_480 {
      width: 24px;
      height: 24px;
    }

    &.notActive {
      transform: rotate(-90deg);
    }
  }
}

.answer {
  color: var(--secondary-text-color);

  &.open {
    margin: 16px 0 0 0;

    @include block_576 {
      margin: 8px 0 0 0;
    }
  }
}

