@import 'src/styles/mixins';

.token {
  padding-top: 172px;
  padding-bottom: 216px;

  @include block_1440 {
    padding-top: 153px;
    padding-bottom: 232px;
  }

  @include block_992 {
    padding-top: 80px;
    padding-bottom: 115px;
  }

  @include block_768 {
    padding-top: 47px;
    padding-bottom: 104px;
  }

  @include block_576 {
    padding-top: 32px;
    padding-bottom: 136px;
  }

  @include block_320 {
    padding-top: 24px;
    padding-bottom: 92px;
  }
}

.container {
  @include flex($gap: 124px);

  @include block_1440 {
    gap: 118px
  }

  @include block_992 {
    flex-wrap: wrap;
    justify-content: center;
    gap: 36px;

  }

  @include block_768 {
    gap: 12px;
  }
}

.leftSide {
  @include flex($direction: column, $h_align: flex-start);
}

.rightSide {
  @include flex($direction: column, $v_align: center);
  flex-shrink: 0;

  @include block_768 {
    margin-top: 18px;
  }

  @include block_576 {
    margin-top: 12px;
  }
}

.title {
  span {
    color: var(--primary-main-color);
  }

  @include block_768 {
   text-align: center;
  }
}

.description {
  margin-top: 16px;
  max-width: 690px;
  color: var(--secondary-text-color);

  @include block_768 {
    text-align: center;
  }
}

.tokenImg {
  max-width: 460px;
  width: 100%;
  position: relative;
  animation: token-img-animation;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;

  @include block_1440 {
    max-width: 400px;
  }

  @include block_992 {
    max-width: 371px;
  }

  @include block_768 {
    max-width: 300px;
  }

  @include block_576 {
    max-width: 240px;
  }
}

@keyframes token-img-animation {
    0% {
      transform: translateY(-10px);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
}