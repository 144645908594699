@import 'src/styles/mixins';

.form {
  @include flex($wrap: wrap, $gap: 16px)
}

.formInputLabel, .formInputLabelWrapper {
  width: 100%;
}

.formInputAddress {
  @include flex($direction: column, $gap: 16px)
}

.formInputBtns {
  @include flex($direction: row, $gap: 16px);

    @include block_576 {
    width: 100%;
  }
}

.connectWallet {
  @include flex();

  width: 100%;
}

.logoutWallet {
 white-space: nowrap;
}

.formInputTextWrapper {
  @include flex($h_align: space-between)
}

.formInputText {
  margin-bottom: 6px;
}

.additionalText {
  color: var(--tertiary-text-color);
}

.formInputContainer {
  padding: 8px 12px;
  background: linear-gradient(0deg, #fff, #fff) padding-box, linear-gradient(180deg, #fafafa 0%, #d6d6d6 100%) border-box;
  border: 1px solid transparent;
  border-radius: 10px;

  input {
    background: none;

    &:hover {
      box-shadow: none;
    }
  }
}

.formInputCheckboxes {
  @include flex($direction: column, $gap: 8px)
}

.formInput {
  width: 100%;
  max-height: 48px;
  height: 100%;
}

.formInputLabelNetwork, .formInputLabelCoin {
  transition: flex-basis 0.6s ease-out;
  flex: 1 1 0;
}

.defaultAmounts {
  @include flex();
  padding-top: 6px;
}

.defaultAmount {
  flex: 1 1 0;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  color: var(--tertiary-text-color);
  border-radius: 8px;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;

  &:hover {
    background-color: var(--primary-outlined-hover-color);
    color: var(--primary-main-color);
  }
}

.formInputContainer {
  background: linear-gradient(0deg, #fff, #fff) padding-box,
  linear-gradient(180deg, #fafafa 0%, #d6d6d6 100%) border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 8px 8px 8px 12px;

  &:focus-within {
    border: 1px solid #1a82fb;
  }

  .formInput {
    border-width: 0;
    border-radius: 0;
    padding: 7px 205px 7px 0;
    position: relative;

    &:focus {
      border-width: 0;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.formInputWrapper {
  position: relative;
  padding-bottom: 6px;

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--nav-divider-color);
  }
}

.amountToken {
  @include absolute_vertical_alignment;
  right: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--secondary-text-color)
}

.formInputCheckbox {
  @include flex($gap: 8px, $v_align: center);

  label {
    background: none;

    &:hover {
      background: none;
    }

    & > div {
      background-color: var(--primary-contrast-color);
      border-radius: 4px;
    }

    input {
      &:focus {
        & + div {
          box-shadow: var(--shadow);
          border-radius: 4px;
        }
      }
    }
  }

  .formInputText, .formDescription {
    color: var(--secondary-text-color);
    user-select: none;
    margin-bottom: 0;

    a {
      color: var(--primary-main-color);
      text-decoration: underline;
    }
  }
}

.formDescription {
  color: var(--secondary-text-color);
  user-select: none;
  margin-bottom: 0;

  a {
    color: var(--primary-main-color);
    text-decoration: underline;
  }
}

.submitBtn {
  white-space: nowrap;
  text-transform: capitalize;

  @include block_576 {
    width: 100%;
  }
}

.error {
  border: 1px solid #f00f;
}

.errorText {
  color: #f00f;
  font-size: 0.875rem;
  margin-top: 5px;
}

.submitMessage {
  word-break: break-word;
  text-align: left;
  align-self: flex-start;
  margin-top: 16px;
  flex-wrap: nowrap;

  @include block_576 {
    margin-top: 8px;
  }

  p > a {
    text-decoration: underline;
  }
}

.metaMaskLogo {
  flex-shrink: 0;
  margin-left: 8px;
  width: 32px;
  height: 32px;
}

.twitterLink:not(.default) {
  @include flex($gap: 8px);

  color: var(--primary-main-color);
  border-color: var(--primary-main-color);
  padding: 12px 24px;
  border-radius: 8px;

  @include block_576 {
    width: 100%;
  }

  &.disabled {
    pointer-events: none;
    color: var(--nav-disabled-color);
    border-color: var(--nav-disabled-color);
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.claimForm {
  gap: 0;

  .formInputWrapper {
    @include flex($wrap: nowrap, $gap: 16px, $v_align: center);
    width: 100%;

    &:after {
      content: unset;
    }

    *:nth-child(2) {
     flex-shrink: 0;
    }

    @include block_576 {
      flex-wrap: wrap;
    }
  }

  .inputWrapper {
    width: 100%;
    position: relative;
  }

  .roundSelect {
    @include absolute_vertical_alignment;
    right: 8px;
  }
}

.pinksale {
  background: linear-gradient(0deg,#fff,#fff) padding-box,linear-gradient(180deg,#fafafa,#d6d6d6) border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 12px;
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.015rem;
  line-height: 1.25;
  display: flex;
  align-items: center;
  max-height: 48px;

  @include block_480 {
    font-size: 14px;
  }
}

.pinksaleLink:not(.default) {
  background-color: #FDEAF1;
  color: #F95192;
  padding: 4px 10px;
  border-radius: 6px;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #F95192;
  transition: opacity .3s ease-out;

  &:hover {
    opacity: 0.8;
  }
}

.pinksaleImg {
  margin-left: 8px;
}

.walletsNotificate {
  text-align: left;
}

.questions {
  color: var(--secondary-text-color);

  a {
    color: var(--primary-main-color);
    text-decoration: underline;
  }
}