@import 'src/styles/mixins';

.claim {
  padding: 155px 0 185px 0;

  @include block_992 {
    padding: 100px 0;
  }

  @include block_576 {
    padding: 40px 0 32px 0;
  }

  @include block_360 {
    padding: 60px 0 32px 0;
  }
}

.container {
  @include flex($gap: 16px, $direction: column);
  background: linear-gradient(100.26deg, rgba(60, 151, 255, 0.02) 0%, rgba(60, 151, 255, 0.1) 100%), #FFFFFF;
  border-radius: 24px;
  padding: 32px;
  transition: height 0.6s ease-out;

  h3 {
    color: var(--primary-text-color);
  }

  @include block_576 {
    padding: 8px;
    border-radius: 8px;
  }
}

.claimInfo {
  color: var(--secondary-text-color);
}

.claimInfoLink {
  display: contents;
  margin-left: 30px;
  color: var(--primary-main-color);
}