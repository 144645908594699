@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: bold;
	src: url('CreatoDisplay-Bold.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: 900;
	src: url('CreatoDisplay-Black.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: 900;
	src: url('CreatoDisplay-BlackItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: bold;
	src: url('CreatoDisplay-BoldItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: normal;
	src: url('CreatoDisplay-Italic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: 100;
	src: url('CreatoDisplay-ThinItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: bold;
	src: url('CreatoDisplay-ExtraBold.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: 300;
	src: url('CreatoDisplay-LightItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: normal;
	src: url('CreatoDisplay-Regular.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: 300;
	src: url('CreatoDisplay-Light.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: bold;
	src: url('CreatoDisplay-ExtraBoldItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: italic;
	font-weight: 500;
	src: url('CreatoDisplay-MediumItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: 500;
	src: url('CreatoDisplay-Medium.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Creato Display';
	font-style: normal;
	font-weight: 100;
	src: url('CreatoDisplay-Thin.woff2') format('woff2');
}
