@import 'src/styles/mixins';

.information {
  padding-top: 198px;
  padding-bottom: 156px;

  @include block_1440 {
    padding-top: 149px;
    padding-bottom: 149px;
  }

  @include block_768 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include block_576 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.containerTitle {
  margin-bottom: 16px;
}

.grids {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 16px;

  @include block_992 {
    gap: 16px;
  }

  @include block_768 {
    gap: 12px;
    grid-template-rows: repeat(5, 1fr);
  }

  @include block_576 {
    gap: 8px;
  }
}

.grid {
  @include flex($direction: column, $gap: 6px, $h_align: space-evenly);
  background: var(--primary-contrast-color);
  border-radius: 24px;
  padding: 16px;
  text-align: center;

  @include block_992 {
    padding: 24px;
  }

  @include block_768 {
    border-radius: 16px;
    padding: 24px 12px;
  }

  @include block_576 {
    border-radius: 8px;
    padding: 18px 8px;
  }

  h5 {
    color: var(--tertiary-text-color);
  }

  div {
    @include flex($direction: column, $gap: 8px);
    margin-bottom: 7px;
  }

  a {
    word-break: break-word;
  }


  &:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;

    @include block_768 {
      grid-area: 1 / 1 / 2 / 3;
    }
  }

  &:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;

    @include block_768 {
      grid-area: 1 / 3 / 2 / 4;
    }
  }

  &:nth-child(3) {
    grid-area: 2 / 1 / 3 / 3;

    @include block_768 {
      grid-area: 2 / 1 / 3 / 4;
    }
  }

  &:nth-child(4) {
    grid-area: 1 / 3 / 3 / 5;

    @include block_768 {
      grid-area: 1 / 4 / 3 / 7;
    }
  }

  &:nth-child(5) {
    grid-area: 1 / 5 / 2 / 7;

    @include block_768 {
      grid-area: 3 / 1 / 4 / 7;
    }
  }

  &:nth-child(6) {
    grid-area: 2 / 5 / 3 / 7;

    @include block_768 {
      grid-area: 4 / 1 / 5 / 7;
    }
  }

  &:nth-child(7) {
    grid-area: 3 / 1 / 4 / 7;

    @include block_768 {
      grid-area: 5 / 1 / 6 / 7;
    }
  }
}

.contractAddress {
  word-break: break-all;
}