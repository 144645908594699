@import 'src/styles/mixins';

.roadmap {
  padding: 135px 0;

  @include block_992 {
    padding: 60px 0;
  }
}

.steps {
  @include flex($direction: column)
}

.step {
  @include flex($direction: column, $v_align: center, $h_align: center, $gap: 16px);

  background: #FFFFFF;
  box-shadow: 0px -32px 13px rgba(0, 17, 51, 0.01), 0px -18px 11px rgba(0, 17, 51, 0.02), 0px -8px 8px rgba(0, 17, 51, 0.03), 0px -2px 4px rgba(0, 17, 51, 0.04), 0px 0px 0px rgba(0, 17, 51, 0.04);
  border-radius: 24px;
  padding: 24px 32px 32px;
  text-align: center;

  @include block_992 {
    border-radius: 24px;
    padding: 16px 24px 24px;
    gap: 8px;
  }

  @include block_768 {
    border-radius: 16px;
    padding: 16px 16px 24px;
  }

  @include block_480 {
    border-radius: 8px;
  }

  &:not(first-child) {
    margin-top: -10px;
  }

  h3 {
    color: var(--primary-text-color);
    margin-bottom: 4px;
  }

  h5 {
    color: var(--primary-text-color);
    width: 104px;
    white-space: nowrap;

    @include block_768 {
      width: 70px;
    }

    &:after {
      @include body-sm;

      padding: 7px 12px;
      position: relative;
      right: -16.5px;
      top: -8px;
      border-radius: 10px;

      @include block_768 {
        top: -3px;
      }
    }

    &.completed {
      &:after {
        content: 'Completed';
        background-color: var(--primary-main-color);
        color: var(--primary-contrast-color);
      }
    }

    &.inProgress {
      &:after {
        content: 'In progress';
        color: var(--primary-main-color);
        background: linear-gradient(0deg, #fff, #fff) padding-box, linear-gradient(180deg, #fafafa, #d6d6d6) border-box;
        border: 1px solid transparent;
      }
    }
  }

  ul {
    @include flex($wrap: wrap, $c_gap: 16px, $h_align: space-between);

    text-align: start;
    list-style: disc inside;

    @include block_576 {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }
  }

  li {
    max-width: 45%;
    width: 100%;
    color: var(--secondary-text-color);
    list-style-type: none;
    position: relative;
    padding-left: 24px;

    @include block_576 {
      max-width: 100%;
    }

    &:before {
      content: "\2219";
      font-size: 2.5em;
      line-height: 0;
      position: absolute;
      top: .2em;
      left: 0;
    }
  }
}

.description {
  color: var(--secondary-text-color)
}