@import 'src/styles/mixins';

.header {
  @include flex($v_align: center);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;

  background: rgba(255, 255, 255, 0.6);
  mix-blend-mode: normal;
  box-shadow: 0 2px 20px -2px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(7px);
  z-index: 1;

  @include block_992 {
    height: 53px;
  }
}

.nav {
  @include flex($v_align: center);
  padding: 9.5px 80px;
  width: 100%;
  max-width: 1760px;
  margin: 0 auto;

  @include block_1440 {
    padding: 9.5px 40px;
    max-width: 100%;
  }

  @include block_768 {
    padding: 0 40px;
  }

  @include block_576 {
    padding: 0 16px;
  }
}

.logo {
  @include flex($v_align: center, $gap: 10px);
  margin-right: auto;
}

.logoIcon {
  @include block_992 {
    width: 36px;
    height: 36px;
  }
}

.desktopOnly {
  @include block_1440 {
    display: none;
  }
}

.menuWrapper {
  transition: height 600ms ease-out;

  & > div {
    @include flex($v_align: center, $gap: 155px);
    transition: height 600ms ease-out;


    @include block_1440 {
      gap: 32px;
    }

    @include block_1200 {
      gap: 16px;
    }
  }

  &.mobileOnly {
    @include block_1200 {
      width: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.1s ease-out;


      &.active {
        @include flex($all_included: true);

        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        opacity: 1;
        visibility: visible;
        width: 100%;
        height: 100vh;
      }

      & > div {
        flex-direction: column;
        justify-content: center;
        height: 100vh;
      }
    }
  }
}

.menu {
  @include flex($gap: 32px, $v_align: center);

  @include block_1200 {
    flex-direction: column;
  }
}

.menuLink {
  color: var(--nav-default-color);

  &.active {
    color: var(--primary-main-color);
  }

  &:hover {
    color: var(--nav-hover-color);
  }
}

.goToLanding {
  padding: 0;
}

.goToApp {
  white-space: nowrap;
}

.hamburger {
  @include flex($v_h_align: center);

  background-color: #fff;
  border-radius: 8px;
  color: var(--nav-default-color);
  height: 32px;
  position: relative;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
  width: 32px;
  z-index: 2;

  &.active {
    .hamburgerInner {
      transform: rotate(45deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        opacity: 0;
        top: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease,
        transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  &.mobileOnly {
    display: none;

    @include block_1200 {
      display: flex;
    }
  }
}

.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  background-color: var(--nav-default-color);
  border-radius: 1px;
  height: 2px;
  position: absolute;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 20px;
}

.hamburgerInner {
  top: 50%;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: -6px;
    transition: top 0.1s 0.14s ease, opacity 0.1s ease;
  }

  &::after {
    bottom: -6px;
    transition: bottom 0.1s 0.14s ease,
    transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}


