@import 'src/styles/mixins';

.steps {
  @include flex($direction: column, $gap: 12px);
}

.step {
  @include flex($direction: column);
  background-color: var(--primary-contrast-color);
  border-radius: 10px;
  padding: 8px;

  @include block_576 {
    border-radius: 8px;
  }
}

.title {
  @include flex($h_align: flex-start, $gap: 12px);
  color: var(--primary-text-color);

  svg {
    width: 34px;
    height: 34px;
    margin-left: auto;
    color: var(--nav-default-color);
    transition: transform 0.6s ease-out;

    @include block_480 {
      width: 24px;
      height: 24px;
    }

    &.notActive {
      transform: rotate(-90deg);
    }
  }
}

.badge {
  display: unset;
  border-radius: 10px;
  color: var(--secondary-text-color);
  background: linear-gradient(0deg, #fff, #fff) padding-box, linear-gradient(180deg, #fafafa, #d6d6d6) border-box;
  border: 1px solid transparent;
  padding: 5px 12px;

  &:not(.default) {
    line-height: 1;
  }

  &.active {
    background: var(--primary-main-color) none;
    color: var(--primary-contrast-color);
  }

  @include block_480 {
    width: auto;
  }
}

.contentItem {
  @include flex($h_align: space-between, $wrap: wrap);
  padding: 8px;
  position: relative;
  color: var(--primary-text-color);

  &.progress {
    gap: 6px;
  }

  &:not(.progress):after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--nav-divider-color);
  }
}

.progressBar {
  width: 100%;
  background-color: var(--primary-outlined-hover-color);
  height: 8px;
  padding: 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &:after {
    display: none;
  }
}

.progressBarActive {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-main-color);
  height: 8px;
  transition: width 0.3s ease-out;
}

.swapContainer {
  @include flex($direction: column, $v_align: center);

  p {
    padding: 8px;
    margin-bottom: 12px;
  }
}

.swapBlocks {
  @include flex($h_align: space-evenly);
  width: 100%;
  @media (max-width: 769.98px) {
    flex-direction: column;    
  }

}

.swapBlock {
  @include flex($direction: column, $v_align: center, $gap: 14px);

  h6 {
    color: var(--primary-text-color);
  }

  .badge {
    color: var(--primary-main-color);
    margin-bottom: 12px;
  }
}
