@import 'src/styles/mixins';

.icon {
	flex-shrink: 0;
}

.infoIcon {
	@include block_480 {
		height: 16px;
		width: 16px;
	}
}
