@import 'src/styles/mixins';

.utility {
  padding: 163px 0;

  @include block_1440 {
    padding: 100px 0;
  }

  @include block_576 {
    padding: 60px 0;
  }
}

.container {
  @include flex($direction: column);
}

.blocks {
  @include flex($wrap: wrap, $gap: 24px);

  @include block_576 {
    gap: 16px
  }

  @include block_480 {
    gap: 8px
  }
}

.block {
  display: flex;
  flex-direction: column;
  flex: 1 30%;
  gap: 24px;
  background-color: #fff;
  padding: 16px;
  border-radius: 24px;
  justify-content: center;
  text-align: center;

  @include block_768 {
    flex-direction: row;
    gap: 16px;
    border-radius: 16px;
  }

  @include block_576 {
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
  }

  h4 {
    margin-bottom: 16px;
  }

  img {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 280px;

    @include block_992 {
      max-width: 260px;
    }

    @include block_768 {
      max-width: 150px;
      max-height: 200px;
      margin-bottom: auto;
    }
  }
}