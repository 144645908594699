@import 'src/styles/mixins';

.allocation {
  padding: 84px 0;

  @include block_576 {
    padding: 60px 0;
  }
}

.container {
  display: flex;
  gap: 48px;

  @include block_992 {
    flex-wrap: wrap;
  }
}

//.containerTitle {
//  width: 100%;
//  padding: 24px;
//}

.leftSide, .rightSide {
  flex: 1 1 0;
}

.leftSide {
  h3 {
    margin-bottom: 48px;
  }
}