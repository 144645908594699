@import 'src/styles/mixins';

.bgs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg {
  position: absolute;
  width: 150vw;
  height: 150vw;
  background: radial-gradient(50% 50% at 50% 50%, #1464FF 0%, rgba(20, 100, 255, 0) 100%);
  opacity: 0.12;

  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;

  @include block_480 {
    width: 400vw;
    height: 400vw;
  }

  &:nth-child(even) {
    right: -50%;
  }

  &:nth-child(odd) {
    left: -50%;
  }

  &:nth-of-type(1) {
    top: -10%;
    animation-name: bg1-animation;

    @include block_480 {
      top: 0;
    }
  }

  &:nth-child(2) {
    top: 10%;
    animation-name: bg2-animation;

    @include block_480 {
      top: 22%;
    }
  }

  &:nth-child(3) {
    top: 30%;
    animation-name: bg3-animation;

    @include block_480 {
      top: 50%;
    }
  }

  &:nth-child(4) {
    top: 50%;
    animation-name: bg4-animation;

    @include block_480 {
      top: 65%;
    }
  }
}

@keyframes bg1-animation {
  0% {
    top: -10%;
    left: -50%;
  }
  50% {
    top: -5%;
    left: -20%;
  }
  100% {
    top: -10%;
    left: -50%;
  }
}

@keyframes bg2-animation {
  0% {
    top: 10%;
    right: -50%;
  }
  50% {
    top: 5%;
    right: -20%;
  }
  100% {
    top: 10%;
    right: -50%;
  }
}

@keyframes bg3-animation {
  0% {
    top: 30%;
    left: -50%;
  }
  50% {
    top: 25%;
    left: -20%;
  }
  100% {
    top: 30%;
    left: -50%;
  }
}

@keyframes bg4-animation {
  0% {
    top: 50%;
    right: -50%;
  }
  50% {
    top: 45%;
    right: -20%;
  }
  100% {
    top: 50%;
    right: -50%;
  }
}

