@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.rotateAnimation {
	animation: rotate 1s infinite linear;
}
