@import 'src/styles/mixins';

.faq {
  padding: 158px 0;

  @include block_992 {
    padding: 123px 0;
  }

  @include block_768 {
    padding: 100px 0;
  }

  @include block_576 {
    padding: 60px 0;
  }
}

.container {
  @include flex($direction: column, $gap: 16px);

  @include block_576 {
    gap: 8px
  }
}

.containerTitle:not(.default) {
  margin-bottom: 0;
}

