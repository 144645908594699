@import 'src/styles/mixins';

.select {
	color: var(--nav-default-color);
	cursor: pointer;
	letter-spacing: 0.02em;
	max-width: 100%;
	position: relative;
	text-transform: capitalize;
}

.selectHeader {
	@include flex($v_align: center, $h_align: space-between);

	border: 0;
	color: inherit;
	text-align: left;
	text-transform: capitalize;
	transition: box-shadow 0.1s ease-in, color 0.1s, background-color 0.1s;
	width: 100%;

	&:hover {
		box-shadow: 2px 2px 2px -1px rgb(0 0 0 / 15%);
		color: var(--nav-hover-color);
	}

	&:focus-visible {
		box-shadow: var(--shadow);
	}
}

.zIndexOne {
	z-index: 10;
}

.zIndexZero {
	z-index: 0;
}

span {
	data {
		background: var(--primary-outlined-hover-color);
		border-radius: 4px;
		color: var(--primary-main-color);
		margin-left: 2px;
		padding: 1px 4px;
	}
}

/* Size */
.sm {
	border-radius: 4px;
	column-gap: 2px;
	height: 20px;
	padding: 1px 2px 1px 6px;

	svg {
		height: 18px;
		min-width: 18px;
		width: 18px;
	}
}

.md {
	border-radius: 6px;
	column-gap: 6px;
	height: 32px;
	padding: 6px 6px 6px 8px;

	svg {
		height: 20px;
		min-width: 20px;
		width: 20px;
	}
}

.lg {
	border-radius: 8px;
	column-gap: 6px;
	height: 36px;
	padding: 6px 8px 6px 10px;

	@include block_768 {
		height: 32px;
		padding: 4px;
	}

	svg {
		height: 22px;
		min-width: 22px;
		width: 22px;
	}
}

.xl {
	border-radius: 8px;
	column-gap: 6px;
	height: 48px;
	padding: 12px 8px 12px 12px;

	svg {
		height: 24px;
		min-width: 24px;
		width: 24px;
	}
}

/* Color */
.active {
	color: var(--active-action-color);
}

.secondary {
	background-color: var(--hover-action-color);
	color: var(--secondary-text-color);
}

/* Background Color */
.primary {
	background-color: var(--primary-contrast-color);
}

.selectList {
	background-color: #f2f7fb;
	border-radius: 8px;
	box-shadow: 0 4px 16px -6px rgb(0 0 0 / 14%);
	min-width: 100%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	transition: 0.1s all;
	visibility: hidden;
	z-index: 1;

	@include block_576 {
		border-radius: 6px;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}

	&.endSide {
		right: 0;
	}
}

.selectListItem {
	transition: background-color 0.1s, color 0.1s;

	&:focus-visible {
		box-shadow: var(--inset-shadow);
	}

	&:hover {
		background-color: #fff;
		color: var(--nav-hover-color);
	}

	data {
		display: flex;
		justify-content: space-between;
		white-space: nowrap;

		data {
			margin-left: 6px;
		}
	}
}

.selectListItemSm {
	padding: 3px 8px;
}

.selectListItemMd {
	padding: 7px 8px;
}

.selectListItemLgXl {
	padding: 8px 12px;
}
