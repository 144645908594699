.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(227, 227, 229, 0.4);
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  z-index: 1;
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

.active {
  opacity: 1;
  visibility: visible;
}

.modalClose {
  position: absolute;
  top: 12px;
  right: 12px;
}