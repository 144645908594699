@import 'src/styles/mixins';

.timer {
  @include flex($gap: 8px, $v_align: center);
  margin-top: 32px;

  @include block_768 {
    align-self: center;
  }

  p {
    width: 49px;
    text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0.08em;
    color: var(--primary-main-color);
    position: relative;

    &:before {
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.06em;
      color: var(--primary-text-color);
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: -12px;
    }
  }

  span {
    font-weight: 600;
    font-size: 24px;
  }
}

.days {
  &:before {
    content: 'days';
  }
}

.hours {
  &:before {
    content: 'Hours';
  }
}

.minutes {
  &:before {
    content: 'Minutes';
  }
}

.seconds {
  &:before {
    content: 'Seconds';
  }
}

.timerMinimal {
  @include flex($gap: 6px, $v_align: center);

  .days, .hours, .minutes, .seconds {
    @include flex($direction: row-reverse);

    &:before {
     top: 0;
    }
  }

  .days {
    &:before {
      content: 'd';
    }
  }

  .hours {
    &:before {
      content: 'h';
    }
  }

  .minutes {
    &:before {
      content: 'm';
    }
  }

  .seconds {
    &:before {
      content: 's';
    }
  }
}